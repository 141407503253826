<template>
<v-btn-toggle>
    <v-btn title="Dowload in .csv format. Works on all devices" class="export-btn" @click="exportToFile(data,fileName,'csv')">
        <v-icon>mdi-file-delimited</v-icon>
    </v-btn>
</v-btn-toggle>
</template>

<script>
export default {
    props: ['data','fileName']
}
</script>

<style lang="scss" scoped>
.export-btn{
    height: 2rem !important
}
</style>
