<template>
<div>
    <v-card elevation="8" v-if="isAllowed('viewTopVendorsStatsReport')">
        <v-card-text>
            <v-flex ma-2>
                <alert-message v-if="msg" :error="msg"></alert-message>
            </v-flex>
            <v-container>
                <v-flex mt-8>
                    <v-layout align-center wrap>
                        <v-flex xs4 sm3 class="ml-3">
                            <v-select v-model="span" :items="spanItems" title="label" itemText="label" value="value" label="Sales report for *"></v-select>
                        </v-flex>
                        <v-flex xs4 sm3 class="ml-3">
                            <input-date @clear="startDateCleared=true" v-model="startDate" label="Start Date"></input-date>
                        </v-flex>
                        <v-flex xs4 sm3 class=" ml-3 mr-5">
                            <input-date @clear="endDateCleared=true" v-model="endDate" label="End Date"></input-date>
                        </v-flex>
                        <v-flex>
                            <v-btn mb-6 outlined :style="themeInverted" @click="generateReport()">Generate</v-btn>
                        </v-flex>
                    </v-layout>
                </v-flex>
                <v-flex mt-4>
                    <v-flex text-right>
                        <export-to-csv :data="items" :fileName="`vendor-stats-report-from-${reportStartDate}-to-${reportEndDate}`"></export-to-csv>
                        <export-to-xls :data="items" :fileName="`vendor-stats-report-from-${reportStartDate}-to-${reportEndDate}`"></export-to-xls>
                    </v-flex>
                    <center>
                        <h2>Vendor State report from {{reportStartDate | dateFormat}} to {{reportEndDate | dateFormat}}</h2>
                    </center>
                    <v-data-table :headers="headers" :items="items" :items-per-page="-1" class="elevation-1" :sort-by="['customerCount', 'invoiceCount', 'salesCount']" :sort-desc="[true, true, true]">
                        <template v-slot:item.salesCount="{ item }">
                            {{ item.salesCount | amountFormat}}
                        </template>
                    </v-data-table>

                </v-flex>
            </v-container>
        </v-card-text>
    </v-card>
</div>
</template>

<script>
import appConstants from '@/utils/appConstants'
import ExportToCsv from '@/components/ExportToCsv'
import ExportToXls from '@/components/ExportToXls'
export default {
    components: {
        ExportToCsv,
        ExportToXls
    },
    data() {
        return {
            startDate: null,
            endDate: null,
            reportStartDate: '',
            reportEndDate: '',
            startDateCleared: false,
            endDateCleared: false,
            items: [],
            span: 'monthly',
            spanItems: [{
                    label: 'Week',
                    value: 'weekly'
                },
                {
                    label: 'Month',
                    value: 'monthly'
                },
                {
                    label: 'Quarter',
                    value: 'quarterly'
                }
            ],
            headers: [{
                    text: "Vendor",
                    value: "vendor"
                },
                {
                    text: "Customers",
                    value: "customerCount",
                    align: "right"
                },
                {
                    text: "Invoices",
                    value: "invoiceCount",
                    align: "right"
                },
                {
                    text: "Total Sale",
                    value: "salesCount",
                    align: "right"
                },
            ]
        }
    },
    mounted() {
        this.startDate = null
        this.endDate = null
        this.generateReport()
    },
    methods: {
        async generateReport() {
            try {
                var responseData = await this.generateReportData(appConstants.ALL_VENDOR_BUSINESS_STATS, this.span, this.startDate, this.endDate, this.startDateCleared, this.endDateCleared)
                this.items = responseData.data
                this.reportStartDate = responseData.startDate
                this.reportEndDate = responseData.endDate
                this.endDateCleared = false
                this.startDateCleared = false
                this.msg = null
            } catch (error) {
                this.handleError(error)
                this.msg = error.response.data
            }
        }
    },

}
</script>

<style lang="sass" scoped>

</style>
