<template>
<v-btn-toggle>
    <v-btn title="Download in .xls format. This option required microsoft excel install on the device" class="excel-btn" @click="exportToFile(data,fileName,'xls')">
        <v-icon class="icon-color">mdi-microsoft-excel</v-icon>
    </v-btn>
</v-btn-toggle>
</template>

<script>
export default {
    props: ['data','fileName']
}
</script>

<style lang="scss" scoped>
.excel-btn{
    height: 2rem !important;
    background: green !important;
}
.icon-color{
    color: white !important;
}
</style>
